<template>
  <div class="news">
    <XwdtPageLayout :currentActiveTitle="activeTitle">
      <template v-slot:desc>
        <div class="container partyMember">
          <div class="div_mod" id="newsAnchor">
            <!-- 栏目简介 -->
            <div class="div_text" v-html="activeBz"></div>
          </div>
        </div>
      </template>
      <template v-slot:search>
        <div class="div_chooseBox">
          <el-select
            v-model="activePath"
            value-key=""
            placeholder="年份选择"
            clearable
            filterable
            @change="handlePathChange"
          >
            <el-option
              v-for="item in options"
              :key="item.value"
              :label="item.label"
              :value="item.value"
            >
            </el-option>
          </el-select>
          <el-select v-model="year" value-key="" placeholder="年份选择" clearable filterable @change="handleYearChange">
            <el-option
              v-for="item in years"
              :key="item.value"
              :label="item.label"
              :value="item.value"
            >
            </el-option>
          </el-select>
        </div>
      </template>

      <template v-slot:list>
        <div class="partyMember">
          <div class="news_list3">
            <ul>
              <li v-for="article in articles" :key="article.id">
                <PartyMemberCard :article="article" />
              </li>
            </ul>
          </div>
        </div>
      </template>

      <template v-slot:pagination>
        <el-pagination
          class="div_page"
          background
          layout="prev, pager, next"
          prev-text="上一页"
          next-text="下一页"
          :total="+total"
          :current-page="+currentPage"
          :page-size="pageSize"
          :hide-on-single-page="true"
          @current-change="handleCurrentChange"
        >
        </el-pagination>
      </template>
    </XwdtPageLayout>
  </div>
</template>

<script>
import XwdtPageLayout from './components/XwdtPageLayout.vue'
import PartyMemberCard from './components/PartyMemberCard.vue'
import { mapState } from 'vuex'
import portalApi from '../../../lvya-common/src/api/eos/portal'
import { years } from './utils/useYearOptions'

export default {
  components: { XwdtPageLayout, PartyMemberCard },
  data() {
    return {
      options: [],
      activePath: '',
      activeTitle: '',
      activeBz: '',

      articles: [],
      total: 0,
      currentPage: 1,
      pageSize: 999,
      years,
    }
  },
  computed: {
    ...mapState({
      nav: (state) => state.yzsyStore.nav,
    }),
    navTabs() {
      return (
        this.nav
          ?.find((item) => item.code === 'xinwendongtai')
          ?.stypes?.map((nav) => ({
            id: nav.id,
            name: nav.title,
            path: nav.tzurl,
          })) || []
      )
    },
    activeTabId() {
      return this.navTabs?.find((item) => item.path === '/portalYZSYParty')?.id
    },
  },
  methods: {
    async getOptions() {
      let res = { pId: this.activeTabId, pageNum: '1', pageSize: '99999', }
      let resData = await portalApi.getTypeListByPage(res)
      console.log({ resData })
      this.options = resData.list.map((item) => ({
        id: item.id,
        label: item.title,
        value: item.tzurl,
        bz: item.bz,
      }))
      this.activePath = this.options[1].value
      this.activeTitle = this.options[1].label
      this.activeBz = this.options[1].bz
    },
    handlePathChange(path) {
      this.$router.push({ path })
    },
    handleCurrentChange(currentPage) {
      this.currentPage = currentPage
      this.getArticles()
    },
    async getArticles() {
      // const portalSchoolInfo = JSON.parse(
      //   localStorage.getItem('portalSchoolInfo')
      // )
      const typeId = this.options.find(
        (item) => item.value === this.activePath
      ).id
      let reqData = {
        noSchoolId: true,
        schoolId: '7',
        pageNum: this.currentPage,
        pageSize: this.pageSize,
        typeId: typeId,
        isContent: 'Y',
        year: this.year || undefined,
      }
      let resData = await portalApi.getArtsByPage(reqData)
      console.log({ resData })
      this.articles = resData.list
      this.total = resData.total
    },
    async handleYearChange(year) {
      this.year = year
      await this.getArticles()
    }
  },
  async mounted() {
    await this.getOptions()
    this.getArticles()
  },
}
</script>

<style lang="less" scoped>
@import "./style/common.css";
@import "./style/global.css";
@import "./style/news.css";
</style>
